/* SidebarNavigation.css */
.sidebar-navigation {
    padding: 15px;
    position: fixed; /* This makes the sidebar fixed on the screen */
    top: 12rem; /* Adjust the top position as needed */
    left: 0; /* Align the sidebar to the left */
    height: calc(100vh - 15rem); /* Set the height of the sidebar */
    overflow-y: auto; /* Enable scrolling inside the sidebar if content overflows */
    width: 250px; /* Set a fixed width for the sidebar */
    background-color: #f8f9fa; /* Optional: Add background color to the sidebar */
    border-right: 1px solid #ddd; /* Optional: Add a border for a better visual separation */
  }
  
  .search-and-filter {
    margin-bottom: 20px;
  }
  
  .search-and-filter input {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px 12px;
  }
  
  .filters {
    margin-top: 10px;
  }
  
  .filters .form-check {
    display: flex;
    align-items: center;
  }
  
  .filters .form-check-input {
    margin-right: 10px;
  }
  
  .filters .form-check-label {
    font-size: 14px;
    margin-left: 20px;
  }
  
  .nav-link {
    color: #343a40;
    text-decoration: none;
    font-size: 16px;
    margin-bottom: 5px;
    display: block;
    padding: 8px 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .nav-link:hover {
    background-color: #e9ecef;
    color: #007bff;
  }
  
  .nav-link.active {
    background-color: #007bff;
    color: white;
  }
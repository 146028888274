.full-menu-btn {
    /* Base styles for the button */
    padding: 10px 20px;
    font-size: 16px;
    transition: all 0.3s ease; /* Smooth transition for the hover effect */
  }
  
  .full-menu-btn:hover {
    /* Styles for the hover effect */
    transform: scale(1.1); /* Increase the size of the button */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a shadow */
  }
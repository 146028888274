/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1090;
  }
  
  .modal-content {
    position: relative;
    background: #fff;
    padding: 5px;
    border-radius: 5px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
  }
  
  .modal-image {
    max-width: 100%;
    max-height: 80vh;
    display: block;
    margin: 0 auto;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
    color: #FBB448;
    /* color: #e73bab; */
  }
.container {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .active-subscriptions {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .subscription-search {
    margin-bottom: 15px;
    width: 100%;
  }
  
  .table {
    width: 100%;
    margin-top: 10px;
  }
  
  .table th, .table td {
    text-align: left;
  }
